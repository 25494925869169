import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  addIssueComment,
  deleteIssueComment,
  getIssue,
  updateIssueComment
} from "../../../services/issues/issues.service";
import InfiniteLoadingBar from "../components/InfiniteLoadingBar";
import LoadingSpinnerBox from "../components/LoadingSpinnerBox";
import Comments from "../../../components/Comments/Comments";
import { toast } from "react-toastify";

export default function IssueDetails() {
  const { orgId, issueId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [issue, setIssue] = useState(null);

  useEffect(() => {
    document.title = "Floumy | Issue Details";

    async function fetchIssue(orgId, issueId) {
      try {
        setIsLoading(true);
        const issue = await getIssue(orgId, issueId);
        setIssue(issue);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }

    fetchIssue(orgId, issueId);
  }, [orgId, issueId]);

  async function handleCommentAdd(comment) {
    try {
      const addedComment = await addIssueComment(orgId, issue.id, comment);
      issue.comments.push(addedComment);
      setIssue({ ...issue });
      toast.success("Comment added successfully");
    } catch (e) {
      toast.error("Failed to add comment");
    }
  }

  async function handleCommentUpdate(commentId, content) {
    try {
      const updatedComment = await updateIssueComment(orgId, issue.id, commentId, content);
      const index = issue.comments.findIndex((c) => c.id === updatedComment.id);
      issue.comments[index] = updatedComment;
      setIssue({ ...issue });
      toast.success("Comment updated successfully");
    } catch (e) {
      toast.error("Failed to update comment");
    }
  }

  async function handleCommentDelete(commentId) {
    try {
      await deleteIssueComment(orgId, issue.id, commentId);
      const index = issue.comments.findIndex((c) => c.id === commentId);
      issue.comments.splice(index, 1);
      setIssue({ ...issue });
      toast.success("Comment deleted successfully");
    } catch (e) {
      toast.error("Failed to delete comment");
    }
  }

  return (
    <>
      {isLoading && <InfiniteLoadingBar />}
      <SimpleHeader
        headerButtons={[
          {
            name: "Back",
            shortcut: "←",
            action: () => {
              window.history.back();
            }
          }
        ]}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <div className="card-wrapper">
              {isLoading && (
                <Card>
                  <CardHeader>
                    <h2>Issue</h2>
                  </CardHeader>
                  <LoadingSpinnerBox />
                </Card>
              )}
              {!isLoading && issue && (
                <Card>
                  <CardHeader>
                    <h2>{issue.title}</h2>
                  </CardHeader>
                  <CardBody>
                    <p>{issue.description}</p>
                  </CardBody>
                </Card>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Comments comments={issue?.comments}
                      onCommentAdd={handleCommentAdd}
                      onCommentEdit={handleCommentUpdate}
                      onCommentDelete={handleCommentDelete} />
          </Col>
        </Row>
      </Container>
    </>
  );
}