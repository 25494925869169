import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  addFeatureComment,
  deleteFeatureComment,
  getPublicFeature,
  updateFeatureComment
} from "../../../services/roadmap/roadmap.service";
import LoadingSpinnerBox from "../components/LoadingSpinnerBox";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import InfiniteLoadingBar from "../components/InfiniteLoadingBar";
import { sortByPriority } from "../../../services/utils/utils";
import NotFoundCard from "../components/NotFoundCard";
import ExecutionStats from "../components/stats/ExecutionStats";
import PublicFeature from "./PublicFeature";
import PublicWorkItemsList from "../backlog/PublicWorkItemsList";
import { toast } from "react-toastify";
import Comments from "../../../components/Comments/Comments";

export function PublicDetailFeature() {
  const [feature, setFeature] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { orgId, featureId } = useParams();
  const paymentPlan = localStorage.getItem("paymentPlan");

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const feature = await getPublicFeature(orgId, featureId);
        setFeature(feature);
      } catch (e) {
        console.error(e.message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [orgId, featureId]);

  async function handleCommentAdd(comment) {
    try {
      const addedComment = await addFeatureComment(feature.id, comment);
      feature.comments.push(addedComment);
      setFeature({ ...feature });
      toast.success("Comment added successfully");
    } catch (e) {
      toast.error("Failed to add comment");
    }
  }

  async function handleCommentUpdate(commentId, content) {
    try {
      const updatedComment = await updateFeatureComment(feature.id, commentId, content);
      const index = feature.comments.findIndex((c) => c.id === updatedComment.id);
      feature.comments[index] = updatedComment;
      setFeature({ ...feature });
      toast.success("Comment updated successfully");
    } catch (e) {
      toast.error("Failed to update comment");
    }
  }

  async function handleCommentDelete(commentId) {
    try {
      await deleteFeatureComment(feature.id, commentId);
      const index = feature.comments.findIndex((c) => c.id === commentId);
      feature.comments.splice(index, 1);
      setFeature({ ...feature });
      toast.success("Comment deleted successfully");
    } catch (e) {
      toast.error("Failed to delete comment");
    }
  }

  return (
    <>
      {isLoading && <InfiniteLoadingBar />}
      <SimpleHeader
        headerButtons={[
          {
            name: "Back",
            shortcut: "←",
            action: () => {
              window.history.back();
            }
          }
        ]}
      />
      <Container className="mt--6" fluid id="OKRs">
        {feature && feature.workItems && feature.workItems.length > 0 &&
          <ExecutionStats workItems={feature.workItems} dueDate={feature?.milestone?.dueDate} />}
        <Row>
          <Col>
            {!isLoading && !feature && <NotFoundCard message="Initiative not found" />}
            {!isLoading && feature && <PublicFeature feature={feature} />}
            <Card>
              {isLoading && <LoadingSpinnerBox />}
              {!isLoading && feature &&
                <>
                  <CardHeader className="border-1">
                    <div className="row">
                      <div className="col-12">
                        <h3 className="mb-0">Work Items</h3>
                      </div>
                    </div>
                  </CardHeader>
                  <PublicWorkItemsList
                    orgId={orgId}
                    workItems={sortByPriority(feature.workItems)}
                    showFeature={false}
                  />
                </>
              }
            </Card>
          </Col>
        </Row>
        <Row>
          {!isLoading &&
            <Col>
              <Comments comments={feature?.comments}
                        onCommentAdd={handleCommentAdd}
                        onCommentEdit={handleCommentUpdate}
                        onCommentDelete={handleCommentDelete} />
            </Col>
          }
        </Row>
      </Container>
    </>
  );
}
