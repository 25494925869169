import InfiniteLoadingBar from "../components/InfiniteLoadingBar";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getBuildInPublicSettings, updateBuildInPublicSettings } from "../../../services/bip/build-in-public.service";
import LoadingSpinnerBox from "../components/LoadingSpinnerBox";
import { Link } from "react-router-dom";
import { clearCache } from "../../../services/cache/cache.service";

function BuildInPublic() {
  const [isLoadingBuildInPublicSettings, setIsLoadingBuildInPublicSettings] = useState(false);
  const [isBuildInPublicEnabled, setIsBuildInPublicEnabled] = useState(false);
  const [buildInPublicSettings, setBuildInPublicSettings] = useState({
    isObjectivesPagePublic: false,
    isRoadmapPagePublic: false,
    isIterationsPagePublic: false,
    isActiveIterationsPagePublic: false,
    isFeedPagePublic: false
  });
  const [publicLink, setPublicLink] = useState("");

  function createUrl(path) {
    const protocol = window.location.protocol;
    const host = window.location.host;
    return `${protocol}//${host}${path}`;
  }

  const currentOrg = JSON.parse(localStorage.getItem("currentOrg"));


  useEffect(() => {
    async function fetchData() {
      setIsLoadingBuildInPublicSettings(true);
      try {
        const buildInPublicSettings = await getBuildInPublicSettings();
        setBuildInPublicSettings({
          isObjectivesPagePublic: buildInPublicSettings.isObjectivesPagePublic,
          isRoadmapPagePublic: buildInPublicSettings.isRoadmapPagePublic,
          isIterationsPagePublic: buildInPublicSettings.isIterationsPagePublic,
          isActiveIterationsPagePublic: buildInPublicSettings.isActiveIterationsPagePublic,
          isFeedPagePublic: buildInPublicSettings.isFeedPagePublic
        });
        setIsBuildInPublicEnabled(buildInPublicSettings.isBuildInPublicEnabled);
      } catch (error) {
        toast.error("Failed to fetch build in public settings");
      } finally {
        setIsLoadingBuildInPublicSettings(false);
      }
    }

    fetchData();
  }, []);

  function isBuildInPublicEnabledBasedOnSettings(settings) {
    return Object.values(settings).some((value) => value === true);
  }

  function togglePublicPage(page) {
    return async (e) => {
      try {
        const settings = { ...buildInPublicSettings, [page]: e.target.checked };
        setBuildInPublicSettings(settings);
        await updateBuildInPublicSettings({
          ...settings,
          isBuildInPublicEnabled: isBuildInPublicEnabledBasedOnSettings(settings)
        });
        clearCache(`${currentOrg.id}-settings`);
        toast.success("Settings updated successfully");
      } catch (error) {
        toast.error("Failed to update settings");
      }
    };
  }

  useEffect(() => {
    setIsBuildInPublicEnabled(isBuildInPublicEnabledBasedOnSettings(buildInPublicSettings));

    if (buildInPublicSettings.isFeedPagePublic) {
      setPublicLink(createUrl(`/public/org/${currentOrg.id}/feed`));
    } else if (buildInPublicSettings.isObjectivesPagePublic) {
      setPublicLink(createUrl(`/public/org/${currentOrg.id}/objectives`));
    } else if (buildInPublicSettings.isRoadmapPagePublic) {
      setPublicLink(createUrl(`/public/org/${currentOrg.id}/roadmap`));
    } else if (buildInPublicSettings.isIterationsPagePublic) {
      setPublicLink(createUrl(`/public/org/${currentOrg.id}/iterations`));
    } else if (buildInPublicSettings.isActiveIterationsPagePublic) {
      setPublicLink(createUrl(`/public/org/${currentOrg.id}/active-iteration`));
    }
  }, [buildInPublicSettings, currentOrg.id]);

  async function toggleBuildInPublic() {
    try {
      const settings = {
        isObjectivesPagePublic: !isBuildInPublicEnabled,
        isRoadmapPagePublic: !isBuildInPublicEnabled,
        isIterationsPagePublic: !isBuildInPublicEnabled,
        isActiveIterationsPagePublic: !isBuildInPublicEnabled,
        isFeedPagePublic: !isBuildInPublicEnabled
      };
      await updateBuildInPublicSettings({ ...settings, isBuildInPublicEnabled: !isBuildInPublicEnabled });
      setIsBuildInPublicEnabled(!isBuildInPublicEnabled);
      setBuildInPublicSettings(settings);
      clearCache(`${currentOrg.id}-settings`);
      toast.success("Settings updated successfully");
    } catch (e) {
      toast.error("Failed to update settings");
    }
  }

  if (currentOrg.paymentPlan === "build-in-private") {
    return (
      <>
        <SimpleHeader />
        <Container className="mt--6" fluid id="OKRs">
          <Row>
            <Col>
              <Card className="mb-5">
                <CardHeader>
                  <CardTitle tag="h2" className="mb-3">Build In Public</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <h3>Your current plan does not support Build In Public feature</h3>
                      <button className="btn btn-primary my-3" onClick={() => window.location.href = "/admin/billing"}>
                        Switch your plan to Build In Public
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  return (
    <>
      {isLoadingBuildInPublicSettings && <InfiniteLoadingBar />}
      <SimpleHeader />
      <Container className="mt--6" fluid id="OKRs">
        <Row>
          <Col>
            <Card className="mb-5">
              <CardHeader>
                <Row>
                  <Col xs={12} md={8}>
                    <CardTitle tag="h2" className="mb-3">Build In Public</CardTitle>
                  </Col>
                  <Col xs={12} md={4}>
                    {!isLoadingBuildInPublicSettings && isBuildInPublicEnabled &&
                      <div className="text-xs-left text-sm-right">
                        <Link className="btn btn-icon btn-primary" color="primary" id="tooltipCopyLink" type="button"
                              to={publicLink} target="_blank">
                          Open the public product page <i className="fas fa-external-link ml-2" />
                        </Link>

                      </div>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {isLoadingBuildInPublicSettings &&
                  <Row>
                    <Col className="text-center">
                      <LoadingSpinnerBox />
                    </Col>
                  </Row>}
                {!isLoadingBuildInPublicSettings &&
                  <>
                    <Row className="mb-3">
                      <Col xs={6} sm={3} md={2}>
                        <h3>Public Pages</h3>
                      </Col>
                      <Col xs={6} sm={9} md={10}>
                        <label className="custom-toggle mr-1">
                          <input checked={isBuildInPublicEnabled} onChange={toggleBuildInPublic} type="checkbox" />
                          <span
                            className="custom-toggle-slider"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={6} sm={3} md={2}>
                        Feed
                      </Col>
                      <Col xs={6} sm={9} md={10}>
                        <label className="custom-toggle">
                          <input checked={buildInPublicSettings.isFeedPagePublic}
                                 onChange={togglePublicPage("isFeedPagePublic")} type="checkbox" />
                          <span
                            className="custom-toggle-slider"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={6} sm={3} md={2}>
                        Objectives
                      </Col>
                      <Col xs={6} sm={9} md={10}>
                        <label className="custom-toggle mr-1">
                          <input checked={buildInPublicSettings.isObjectivesPagePublic}
                                 onChange={togglePublicPage("isObjectivesPagePublic")}
                                 type="checkbox" />
                          <span
                            className="custom-toggle-slider"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={6} sm={3} md={2}>
                        Roadmap
                      </Col>
                      <Col xs={6} sm={9} md={10}>
                        <label className="custom-toggle  mr-1">
                          <input checked={buildInPublicSettings.isRoadmapPagePublic}
                                 onChange={togglePublicPage("isRoadmapPagePublic")}
                                 type="checkbox" />
                          <span
                            className="custom-toggle-slider"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={6} sm={3} md={2}>
                        Sprints
                      </Col>
                      <Col xs={6} sm={9} md={10}>
                        <label className="custom-toggle mr-1">
                          <input checked={buildInPublicSettings.isIterationsPagePublic}
                                 onChange={togglePublicPage("isIterationsPagePublic")}
                                 type="checkbox" />
                          <span
                            className="custom-toggle-slider"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={6} sm={3} md={2}>
                        Active Sprint
                      </Col>
                      <Col xs={6} sm={9} md={10}>
                        <label className="custom-toggle">
                          <input checked={buildInPublicSettings.isActiveIterationsPagePublic}
                                 onChange={togglePublicPage("isActiveIterationsPagePublic")} type="checkbox" />
                          <span
                            className="custom-toggle-slider"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                    </Row>
                  </>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BuildInPublic;
