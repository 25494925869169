import InfiniteLoadingBar from "../components/InfiniteLoadingBar";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { Card, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinnerBox from "../components/LoadingSpinnerBox";
import React, { useCallback, useEffect, useState } from "react";
import { listIssues } from "../../../services/issues/issues.service";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Issues({ isPublic = false }) {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const navigate = useNavigate();
  const context = window.location.pathname.includes("public") ? "public" : "admin";
  const { orgId } = useParams();
  const [issues, setIssues] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchIssues = useCallback(async (pageNum, isInitial = false) => {
    if (isInitial) {
      setIsInitialLoading(true);
    } else {
      setIsLoadingMore(true);
    }

    try {
      const newIssues = await listIssues(orgId, pageNum);
      setIssues(prevIssues => isInitial ? newIssues : [...prevIssues, ...newIssues]);
      setHasMore(newIssues.length > 0);
    } catch (e) {
      console.error(e.message);
    } finally {
      if (isInitial) {
        setIsInitialLoading(false);
      } else {
        setIsLoadingMore(false);
      }
    }
  }, [orgId]);

  useEffect(() => {
    document.title = "Floumy | Issues";
    fetchIssues(1, true);
  }, [fetchIssues]);

  const loadNextPage = () => {
    if (!isLoadingMore && hasMore) {
      setPage(prevPage => prevPage + 1);
      fetchIssues(page + 1);
    }
  };

  function navigateToDetailPage(issue) {
    if (isPublic) {
      navigate(`/${context}/org/${orgId}/issues/${issue.id}`);
      return;
    }

    navigate(`/${context}/org/${orgId}/issues/edit/${issue.id}`);
  }

  return (
    <>
      {isInitialLoading && <InfiniteLoadingBar />}
      <SimpleHeader
        headerButtons={[
          {
            name: "New Issue",
            shortcut: "n",
            id: "new-issue",
            action: () => {
              navigate(`/${context}/org/${orgId}/issues/new`);
            }
          }
        ]}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card className="mb-5">
              <CardHeader className="rounded-lg">
                <CardTitle tag="h2">Issues</CardTitle>
              </CardHeader>
              {isInitialLoading ? (
                <LoadingSpinnerBox />
              ) : (
                <InfiniteScroll
                  next={loadNextPage}
                  hasMore={hasMore}
                  loader={isLoadingMore && <LoadingSpinnerBox />}
                  dataLength={issues.length}
                >
                  <div className="table-responsive border-bottom">
                    <table className="table align-items-center no-select" style={{ minWidth: "700px" }}>
                      <thead className="thead-light">
                      <tr>
                        <th scope="col" width="100%">Title</th>
                      </tr>
                      </thead>
                      <tbody className="list">
                      {issues.length === 0 ? (
                        <tr>
                          <td className="text-center">No issues found.</td>
                        </tr>
                      ) : (
                        issues.map(issue => (
                          <tr key={issue.id}
                              onClick={() => navigateToDetailPage(issue)}
                              style={{ cursor: "pointer" }}>
                            <td>{issue.title}</td>
                          </tr>
                        ))
                      )}
                      </tbody>
                    </table>
                  </div>
                </InfiniteScroll>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}