import {
  Card,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import Select2 from "react-select2-wrapper";
import LoadingSpinnerBox from "../components/LoadingSpinnerBox";
import React, { useEffect, useState } from "react";
import PublicWorkItemsList from "./PublicWorkItemsList";

function PublicWorkItemsListCard({
                                   id = "work-items-list-card",
                                   orgId,
                                   title,
                                   workItems,
                                   isLoading,
                                   showFilters = true,
                                   showAssignedTo = true,
                                   showFeature = true,
                                   searchPlaceholder = "Search by title"
                                 }) {
  const [filterByPriority, setFilterByPriority] = useState("all");
  const [filterByType, setFilterByType] = useState("all");
  const [filterByStatus, setFilterByStatus] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [filteredWorkItems, setFilteredWorkItems] = useState([]);

  useEffect(() => {
    setFilteredWorkItems(workItems
      .filter(workItem => {
        if (filterByPriority === "all") {
          return true;
        }

        return workItem.priority === filterByPriority;
      })
      .filter(workItem => {
        if (filterByType === "all") {
          return true;
        }

        return workItem.type === filterByType;
      })
      .filter(workItem => {
        if (filterByStatus === "all") {
          return true;
        }

        return workItem.status === filterByStatus;
      })
      .filter(workItem => {
        if (searchText === "") {
          return true;
        }

        return workItem.title.toLowerCase().includes(searchText.toLowerCase());
      })
    );
  }, [filterByType, filterByPriority, filterByStatus, workItems, searchText]);

  function searchWorkItems(event) {
    const searchText = event.target.value;
    setSearchText(searchText);
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col xs={12} sm={3} className="pb-2">
              <CardTitle tag="h2">{title}</CardTitle>
            </Col>
            {showFilters && <>
              <Col xs={12} sm={3} className="pb-2">
                <Select2
                  className="form-control"
                  defaultValue={filterByPriority}
                  data={[
                    { id: "all", text: "All Priorities" },
                    { id: "high", text: "High" },
                    { id: "medium", text: "Medium" },
                    { id: "low", text: "Low" }
                  ]}
                  options={{
                    placeholder: "Filter by priority"
                  }}
                  onSelect={(e) => {
                    setFilterByPriority(e.target.value);
                  }}
                />
              </Col>
              <Col xs={12} sm={3} className="pb-2">
                <Select2
                  className="form-control"
                  defaultValue={filterByType}
                  data={[
                    { id: "all", text: "All Types" },
                    { id: "user-story", text: "User Story" },
                    { id: "bug", text: "Bug" },
                    { id: "task", text: "Task" },
                    { id: "technical-debt", text: "Technical Debt" },
                    { id: "spike", text: "Spike" }
                  ]}
                  options={{
                    placeholder: "Filter by type"
                  }}
                  onSelect={(e) => {
                    setFilterByType(e.target.value);
                  }}
                />
              </Col>
              <Col xs={12} sm={3} className="pb-2">
                <Select2
                  className="form-control"
                  defaultValue={filterByStatus}
                  data={[
                    { id: "all", text: "All Statuses" },
                    { id: "planned", text: "Planned" },
                    { id: "ready-to-start", text: "Ready to Start" },
                    { id: "in-progress", text: "In Progress" },
                    { id: "blocked", text: "Blocked" },
                    { id: "code-review", text: "Code Review" },
                    { id: "testing", text: "Testing" },
                    { id: "revisions", text: "Revisions" },
                    { id: "ready-for-deployment", text: "Ready for Deployment" },
                    { id: "deployed", text: "Deployed" },
                    { id: "done", text: "Done" },
                    { id: "closed", text: "Closed" }
                  ]}
                  options={{
                    placeholder: "Filter by status"
                  }}
                  onSelect={(e) => {
                    setFilterByStatus(e.target.value);
                  }}
                />
              </Col>
            </>}
          </Row>
        </CardHeader>
        <CardHeader className="py-0">
          <FormGroup className="mb-0">
            <InputGroup className="input-group-lg input-group-flush">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fas fa-search" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={searchPlaceholder}
                type="search"
                value={searchText}
                onChange={searchWorkItems}
              />
            </InputGroup>
          </FormGroup>
        </CardHeader>
        {(!isLoading || filteredWorkItems.length > 0) &&
          <PublicWorkItemsList
            id={id}
            showAssignedTo={showAssignedTo}
            showFeature={showFeature}
            workItems={filteredWorkItems}
            orgId={orgId}
          />}
        {isLoading && <LoadingSpinnerBox />}
      </Card>
    </>
  );
}

export default PublicWorkItemsListCard;
