import InfiniteLoadingBar from "../components/InfiniteLoadingBar";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { Badge, Card, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { featureStatusColorClassName, formatStatus } from "../../../services/utils/utils";
import {
  downvoteFeatureRequest,
  listFeatureRequests,
  listFeatureRequestVotes,
  upvoteFeatureRequest
} from "../../../services/feature-requests/feature-requests.service";
import InfiniteScroll from "react-infinite-scroll-component";
import { isAuthenticated } from "../../../services/auth/auth.service";
import { toast } from "react-toastify";
import LoadingSpinnerBox from "../components/LoadingSpinnerBox";

export default function FeatureRequests({ isPublic = false }) {
  const [isLoading, setIsLoading] = useState(false);
  const [featureRequests, setFeatureRequests] = useState([]);
  const [hasMoreFeatureRequests, setHasMoreFeatureRequests] = useState(true);
  const [featureRequestVotesMap, setFeatureRequestVotesMap] = useState({});
  const { orgId } = useParams();
  const navigate = useNavigate();
  const context = isPublic ? "public" : "admin";
  const [page, setPage] = useState(1);

  async function fetchFeatureRequests(page = 1) {
    setIsLoading(true);
    try {
      const featureRequestsData = await listFeatureRequests(orgId, page, 50);
      if (featureRequestsData.length === 0) {
        setHasMoreFeatureRequests(false);
      } else {
        setFeatureRequests([...featureRequests, ...featureRequestsData]);
      }
    } catch (e) {
      toast.error("Failed to fetch feature requests");
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchFeatureRequestVotes() {
    if (await isAuthenticated()) {
      const featureRequestVotes = await listFeatureRequestVotes(orgId);
      let featureRequestVotesMap = {};
      featureRequestVotes.forEach(featureRequestVote => {
        featureRequestVotesMap[featureRequestVote.featureRequest.id] = featureRequestVote.vote;
      });
      setFeatureRequestVotesMap(featureRequestVotesMap);
    }
  }

  useEffect(() => {
    document.title = "Floumy | Feature Requests";
    fetchFeatureRequests();
    fetchFeatureRequestVotes();
  }, []);

  async function loadNextPage() {
    await fetchFeatureRequests(page + 1);
    setPage(page + 1);
  }

  function getDetailPage(context, orgId, featureRequestId) {
    if (context === "admin") {
      return `/admin/org/${orgId}/feature-requests/edit/${featureRequestId}/`;
    }

    return `/public/org/${orgId}/feature-requests/${featureRequestId}/`;
  }

  return (
    <>
      {isLoading && <InfiniteLoadingBar />}
      <SimpleHeader
        headerButtons={[
          {
            name: "New Request",
            shortcut: "r",
            id: "new-feature-request",
            action: () => {
              navigate(`/${context}/org/${orgId}/feature-requests/new`);
            }
          }
        ]}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card className="mb-5">
              <CardHeader className="rounded-lg">
                <CardTitle tag="h2">Feature Requests</CardTitle>
              </CardHeader>
              <InfiniteScroll next={loadNextPage}
                              hasMore={hasMoreFeatureRequests}
                              loader={<></>}
                              dataLength={featureRequests.length}>

                {(!isLoading || featureRequests?.length > 0) && <div className="table-responsive border-bottom">
                  <table className="table align-items-center no-select" style={{ minWidth: "700px" }}>
                    <thead className="thead-light">
                    <tr>
                      <th scope="col" width="10%">Votes</th>
                      <th scope="col" width="70%">Feature</th>
                      <th scope="col" width="20%">Status</th>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {featureRequests.length === 0 && (<tr>
                      <td colSpan={3} className="text-center">No feature requests found.</td>
                    </tr>)}
                    {featureRequests && featureRequests.map((featureRequest) => (
                      <tr key={featureRequest.id}>
                        <td>
                          <div className="vote badge badge-lg badge-secondary p-2 text-dark"
                               style={{ fontSize: "small", border: "1px solid #cecece" }}>
                            <i className="fa fa-arrow-up" style={{
                              cursor: "pointer",
                              color: featureRequestVotesMap[featureRequest.id] === 1 ? "#81b8fc" : "#cecece"
                            }}
                               onClick={async () => {
                                 if (featureRequestVotesMap[featureRequest.id] === 1) {
                                   return;
                                 }
                                 await upvoteFeatureRequest(orgId, featureRequest.id);
                                 featureRequestVotesMap[featureRequest.id] = 1;
                                 setFeatureRequestVotesMap({ ...featureRequestVotesMap });
                                 featureRequests.forEach(fr => {
                                   if (fr.id === featureRequest.id) {
                                     fr.votesCount++;
                                   }
                                 });
                                 setFeatureRequests([...featureRequests]);
                                 toast.success("You voted up the feature request");
                               }}
                            />
                            <span className="px-3">{featureRequest.votesCount}</span>
                            <i className="fa fa-arrow-down" style={{
                              cursor: "pointer",
                              color: featureRequestVotesMap[featureRequest.id] === -1 ? "#81b8fc" : "#cecece"
                            }}
                               onClick={async () => {
                                 if (featureRequestVotesMap[featureRequest.id] === -1) {
                                   return;
                                 }
                                 await downvoteFeatureRequest(orgId, featureRequest.id);
                                 featureRequestVotesMap[featureRequest.id] = -1;
                                 setFeatureRequestVotesMap({ ...featureRequestVotesMap });
                                 featureRequests.forEach(fr => {
                                   if (fr.id === featureRequest.id) {
                                     fr.votesCount--;
                                   }
                                 });
                                 setFeatureRequests([...featureRequests]);
                                 toast.success("You voted down the feature request");
                               }}
                            />
                          </div>
                        </td>
                        <td className="title-cell" style={{ maxWidth: "300px" }}>
                          <Link to={getDetailPage(context, orgId, featureRequest.id)}>
                            {featureRequest.title}
                          </Link>
                        </td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i className={featureStatusColorClassName(featureRequest.status)} />
                            <span className="status">{formatStatus(featureRequest.status)}</span>
                          </Badge>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>}
                {isLoading && <LoadingSpinnerBox />}
              </InfiniteScroll>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}