import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  addFeatureComment,
  deleteFeatureComment,
  getFeature,
  updateFeature,
  updateFeatureComment
} from "../../../services/roadmap/roadmap.service";
import LoadingSpinnerBox from "../components/LoadingSpinnerBox";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import InfiniteLoadingBar from "../components/InfiniteLoadingBar";
import { sortByPriority } from "../../../services/utils/utils";
import WorkItemsList from "../backlog/WorkItemsList";
import NotFoundCard from "../components/NotFoundCard";
import { addWorkItem } from "../../../services/backlog/backlog.service";
import CreateUpdateDeleteFeature from "./CreateUpdateDeleteFeature";
import ExecutionStats from "../components/stats/ExecutionStats";
import Comments from "../../../components/Comments/Comments";
import { toast } from "react-toastify";

export function DetailFeature() {
  const [feature, setFeature] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const paymentPlan = localStorage.getItem("paymentPlan");

  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const feature = await getFeature(id);
        setFeature(feature);
      } catch (e) {
        toast.error("Failed to fetch initiative");
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [id]);

  async function handleAddWorkItem(workItem) {
    workItem.feature = feature.id;
    const savedWorkItem = await addWorkItem(workItem);
    feature.workItems.push(savedWorkItem);
    sortByPriority(feature.workItems);
    setFeature({ ...feature });
  }

  const handleSubmit = async (feature) => {
    await updateFeature(id, feature);
  };

  function updateWorkItemsChangeStatus(workItems, status) {
    const updatedWorkItems = [];
    for (const workItem of feature.workItems) {
      if (workItems.some((wi) => (wi.id === workItem.id))) {
        workItem.status = status;
      }
      updatedWorkItems.push(workItem);
    }
    feature.workItems = updatedWorkItems;
    setFeature({ ...feature });
  }

  function updateWorkItemsPriority(workItems, priority) {
    const updatedWorkItems = [];
    for (const workItem of feature.workItems) {
      if (workItems.some((wi) => (wi.id === workItem.id))) {
        workItem.priority = priority;
      }
      updatedWorkItems.push(workItem);
    }
    feature.workItems = updatedWorkItems;
    setFeature({ ...feature });
  }

  async function handleCommentAdd(comment) {
    try {
      const addedComment = await addFeatureComment(feature.id, comment);
      feature.comments.push(addedComment);
      setFeature({ ...feature });
      toast.success("Comment added successfully");
    } catch (e) {
      toast.error("Failed to add comment");
    }
  }

  async function handleCommentUpdate(commentId, content) {
    try {
      const updatedComment = await updateFeatureComment(feature.id, commentId, content);
      const index = feature.comments.findIndex((c) => c.id === updatedComment.id);
      feature.comments[index] = updatedComment;
      setFeature({ ...feature });
      toast.success("Comment updated successfully");
    } catch (e) {
      toast.error("Failed to update comment");
    }
  }

  async function handleCommentDelete(commentId) {
    try {
      await deleteFeatureComment(feature.id, commentId);
      const index = feature.comments.findIndex((c) => c.id === commentId);
      feature.comments.splice(index, 1);
      setFeature({ ...feature });
      toast.success("Comment deleted successfully");
    } catch (e) {
      toast.error("Failed to delete comment");
    }
  }

  return (
    <>
      {isLoading && <InfiniteLoadingBar />}
      <SimpleHeader
        headerButtons={[
          {
            name: "Back",
            shortcut: "←",
            action: () => {
              window.history.back();
            }
          }
        ]}
      />
      <Container className="mt--6" fluid id="OKRs">
        {feature && feature.workItems && feature.workItems.length > 0 &&
          <ExecutionStats workItems={feature.workItems} dueDate={feature?.milestone?.dueDate} />}
        <Row>
          <Col>
            {!isLoading && !feature && <NotFoundCard message="Initiative not found" />}
            {!isLoading && feature && <CreateUpdateDeleteFeature onSubmit={handleSubmit} feature={feature} />}
            <Card>
              {isLoading && <LoadingSpinnerBox />}
              {!isLoading && feature &&
                <>
                  <CardHeader className="border-1">
                    <div className="row">
                      <div className="col-12">
                        <h3 className="mb-0">Work Items</h3>
                      </div>
                    </div>
                  </CardHeader>
                  <WorkItemsList
                    workItems={sortByPriority(feature.workItems)}
                    showFeature={false}
                    onAddNewWorkItem={handleAddWorkItem}
                    onChangeStatus={updateWorkItemsChangeStatus}
                    onChangePriority={updateWorkItemsPriority}
                  />
                </>
              }
            </Card>
          </Col>
        </Row>
        <Row>
          {!isLoading &&
            <Col>
              <Comments comments={feature?.comments}
                        onCommentAdd={handleCommentAdd}
                        onCommentEdit={handleCommentUpdate}
                        onCommentDelete={handleCommentDelete} />
            </Col>
          }
        </Row>
      </Container>
    </>
  );
}
