/*!

=========================================================
* Argon Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Link, NavLink as NavLinkRRD, useLocation } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import { Badge, Col, Collapse, Nav, Navbar, NavbarBrand, NavItem, NavLink, Row, UncontrolledTooltip } from "reactstrap";
import ShortcutIcon from "../Shortcuts/ShortcutIcon";

function Sidebar({ toggleSidenav, sidenavOpen, routes, logo, rtlActive }) {
  const location = useLocation();
  const currentOrg = JSON.parse(localStorage.getItem("currentOrg"));
  const orgName = currentOrg ? currentOrg.name : "";
  const orgId = currentOrg ? currentOrg.id : "";
  const paymentPlan = localStorage.getItem("paymentPlan");

  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let route of routes) {
      if (route.collapse && getCollapseInitialState(route.views)) {
        return true;
      } else if (location.pathname.indexOf(route.path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank"
    };
  }

  const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center text-white">
          {logo ? (
            <NavbarBrand {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
                style={{ borderRadius: "5px" }}
              />
              <span style={{ marginLeft: "15px" }} className="text-white text-sm">Floumy</span>
            </NavbarBrand>
          ) : null}
          <div className="ml-auto">
            <div
              className={classnames("sidenav-toggler d-none d-xl-block", {
                active: sidenavOpen
              })}
              onClick={toggleSidenav}
              onKeyDown={toggleSidenav}
              role="button"
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner mb-2">
          <Collapse navbar isOpen={true}>
            {orgName && <h5 className="navbar-product-name text-light text-xl pb-3 text-break">
              {orgName}
            </h5>}
            <a href={`/public/org/${orgId}/feed`} target="_blank"
               className="nav-link text-green text-lg p-0 pb-4 no-visited no-active" rel="noreferrer">
              <span className="nav-link-text">Build In Public<i className="fa fa-external-link px-2" /> </span>
            </a>
            {paymentPlan === "premium" && <div className="mb-3">
              <h6 className="navbar-heading p-0 text-muted">
                <span className="docs-normal" style={{ whiteSpace: "nowrap" }}>Feedback</span>
              </h6>
              <Nav navbar>
                <NavItem>
                  <Row style={{ maxWidth: "100%" }}>
                    <Col xs={7}>
                      <NavLink
                        to={`/admin/org/${orgId}/issues`}
                        onClick={closeSidenav}
                        tag={NavLinkRRD}
                      >
                        <i className="fa fa-exclamation-triangle" />
                        <span className="nav-link-text">Issues</span>
                      </NavLink>
                    </Col>
                    {sidenavOpen && <Col xs={3} style={{ padding: "0.675rem 1.5rem" }} className="text-right">
                      <UncontrolledTooltip target="feed-nav-item" placement="top">
                        This page is public and can be accessed by anyone.
                      </UncontrolledTooltip>
                      <Badge id="feed-nav-item"
                             color="success" pill={true}
                             style={{ cursor: "default" }}>
                        PUBLIC
                      </Badge>
                    </Col>}
                    {sidenavOpen && <Col xs={2} className="text-right pr-2 pt-2">
                    </Col>}
                  </Row>
                </NavItem>
                <NavItem>
                  <Row style={{ maxWidth: "100%" }}>
                    <Col xs={7}>
                      <NavLink
                        to={`/admin/org/${orgId}/feature-requests`}
                        onClick={closeSidenav}
                        tag={NavLinkRRD}
                      >

                        <i className="fa fa-pen-to-square" />
                        <span className="nav-link-text">Feature Requests</span>
                      </NavLink>
                    </Col>
                    {sidenavOpen && <Col xs={3} style={{ padding: "0.675rem 1.5rem" }} className="text-right">
                      <UncontrolledTooltip target="feed-nav-item" placement="top">
                        This page is public and can be accessed by anyone.
                      </UncontrolledTooltip>
                      <Badge id="feed-nav-item"
                             color="success" pill={true}
                             style={{ cursor: "default" }}>
                        PUBLIC
                      </Badge>
                    </Col>}
                    {sidenavOpen && <Col xs={2} className="text-right pr-2 pt-2">
                    </Col>}
                  </Row>
                </NavItem>
              </Nav>
            </div>}
            <h6 className="navbar-heading p-0 text-muted">
              <span className="docs-normal" style={{ whiteSpace: "nowrap" }}>Product</span>
            </h6>
            <Nav navbar>
              <NavItem>
                <Row style={{ maxWidth: "100%" }}>
                  <Col xs={7}>
                    <NavLink
                      to={"/admin/feed"}
                      onClick={closeSidenav}
                      tag={NavLinkRRD}
                    >

                      <i className="fa fa-newspaper" />
                      <span className="nav-link-text">Feed</span>
                    </NavLink>
                  </Col>
                  {sidenavOpen && <Col xs={3} style={{ padding: "0.675rem 1.5rem" }} className="text-right">
                    <UncontrolledTooltip target="feed-nav-item" placement="top">
                      This page is public and can be accessed by anyone.
                    </UncontrolledTooltip>
                    <Badge id="feed-nav-item"
                           color="success" pill={true}
                           style={{ cursor: "default" }}>
                      PUBLIC
                    </Badge>
                  </Col>}
                  {sidenavOpen && <Col xs={2} className="text-right pr-2 pt-2">
                    <ShortcutIcon shortcutKey={1} />
                  </Col>}
                </Row>
              </NavItem>
              <NavItem>
                <Row style={{ maxWidth: "100%" }}>
                  <Col xs={7}>
                    <NavLink
                      to={"/admin/okrs"}
                      onClick={closeSidenav}
                      tag={NavLinkRRD}
                    >

                      <i className="fa fa-bullseye" />
                      <span className="nav-link-text">Objectives</span>
                    </NavLink>
                  </Col>
                  {sidenavOpen && <Col xs={3} style={{ padding: "0.675rem 1.5rem" }} className="text-right">
                    <UncontrolledTooltip target="objectives-nav-item" placement="top">
                      This page is public and can be accessed by anyone.
                    </UncontrolledTooltip>
                    <Badge id="objectives-nav-item"
                           color="success" pill={true}
                           style={{ cursor: "default" }}>
                      PUBLIC
                    </Badge>
                  </Col>}
                  {sidenavOpen && <Col xs={2} className="text-right pr-2 pt-2">
                    <ShortcutIcon shortcutKey={2} />
                  </Col>}
                </Row>
              </NavItem>
              <NavItem>
                <Row style={{ maxWidth: "100%" }}>
                  <Col xs={7}>
                    <NavLink
                      to={"/admin/roadmap"}
                      onClick={closeSidenav}
                      tag={NavLinkRRD}
                    >

                      <i className="fa fa-road" />
                      <span className="nav-link-text">Roadmap</span>
                    </NavLink>
                  </Col>
                  {sidenavOpen && <Col xs={3} style={{ padding: "0.675rem 1.5rem" }} className="text-right">
                    <UncontrolledTooltip target="roadmap-nav-item" placement="top">
                      This page is public and can be accessed by anyone.
                    </UncontrolledTooltip>
                    <Badge id="roadmap-nav-item"
                           color="success" pill={true}
                           style={{ cursor: "default" }}>
                      PUBLIC
                    </Badge>
                  </Col>}
                  {sidenavOpen && <Col xs={2} className="text-right pr-2 pt-2">
                    <ShortcutIcon shortcutKey={3} />
                  </Col>}
                </Row>
              </NavItem>
              <NavItem>
                <Row style={{ maxWidth: "100%" }}>
                  <Col xs={7}>
                    <NavLink
                      to={"/admin/iterations"}
                      onClick={closeSidenav}
                      tag={NavLinkRRD}
                    >

                      <i className="fa fa-refresh" />
                      <span className="nav-link-text">Sprints</span>
                    </NavLink>
                  </Col>
                  {sidenavOpen && <Col xs={3} style={{ padding: "0.675rem 1.5rem" }} className="text-right">
                    <UncontrolledTooltip target="sprints-nav-item" placement="top">
                      This page is public and can be accessed by anyone.
                    </UncontrolledTooltip>
                    <Badge id="sprints-nav-item"
                           color="success" pill={true}
                           style={{ cursor: "default" }}>
                      PUBLIC
                    </Badge>
                  </Col>}
                  {sidenavOpen && <Col xs={2} className="text-right pr-2 pt-2">
                    <ShortcutIcon shortcutKey={4} />
                  </Col>}
                </Row>
              </NavItem>
              <NavItem>
                <Row style={{ maxWidth: "100%" }}>
                  <Col xs={7}>
                    <NavLink
                      to={"/admin/active-iteration"}
                      onClick={closeSidenav}
                      tag={NavLinkRRD}
                    >

                      <i className="fa fa-rocket" />
                      <span className="nav-link-text">Active Sprint</span>
                    </NavLink>
                  </Col>
                  {sidenavOpen && <Col xs={3} style={{ padding: "0.675rem 1.5rem" }} className="text-right">
                    <UncontrolledTooltip target="sprints-nav-item" placement="top" popperClassName="p-2">
                      This page is public and can be accessed by anyone.
                    </UncontrolledTooltip>
                    <Badge id="sprints-nav-item"
                           color="success" pill={true}
                           style={{ cursor: "default" }}>
                      PUBLIC
                    </Badge>
                  </Col>}
                  {sidenavOpen && <Col xs={2} className="text-right pr-2 pt-2">
                    <ShortcutIcon shortcutKey={5} />
                  </Col>}
                </Row>
              </NavItem>
              <NavItem>
                <Row style={{ maxWidth: "100%" }}>
                  <Col xs={8}>
                    <NavLink
                      to={"/admin/work-items"}
                      onClick={closeSidenav}
                      tag={NavLinkRRD}
                    >

                      <i className="fa fa-tasks" />
                      <span className="nav-link-text">All Work Items</span>
                    </NavLink>
                  </Col>
                  {sidenavOpen && <Col xs={4} className="text-right pr-2 pt-2">
                    <ShortcutIcon shortcutKey={6} />
                  </Col>}
                </Row>
              </NavItem>
              <NavItem>
                <Row style={{ maxWidth: "100%" }}>
                  <Col xs={8}>
                    <NavLink
                      to={"/admin/features"}
                      onClick={closeSidenav}
                      tag={NavLinkRRD}
                    >
                      <i className="fa fa-list-alt" />
                      <span className="nav-link-text">All Initiatives</span>
                    </NavLink>
                  </Col>
                  {sidenavOpen && <Col xs={4} className="text-right pr-2 pt-2">
                    <ShortcutIcon shortcutKey={7} />
                  </Col>}
                </Row>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
        <div className="navbar-inner">
          <Collapse navbar isOpen={true}>
            <h6 className="navbar-heading p-0 text-muted">
              <span className="docs-normal" style={{ whiteSpace: "nowrap" }}>Settings</span>
            </h6>
            <Nav className="mb-md-3" navbar>
              {/* TODO: Uncomment this when billing is re-implemented */}
              {/*<NavItem>*/}
              {/*  <NavLink*/}
              {/*    to={"/admin/build-in-public"}*/}
              {/*    onClick={closeSidenav}*/}
              {/*    tag={NavLinkRRD}*/}
              {/*  >*/}
              {/*    <i className="fa fa-eye" />*/}
              {/*    <span className="nav-link-text">Build In Public</span>*/}
              {/*  </NavLink>*/}
              {/*</NavItem>*/}
              <NavItem>
                <NavLink
                  to={"/admin/members"}
                  onClick={closeSidenav}
                  tag={NavLinkRRD}
                >
                  <i className="fa fa-users" />
                  <span className="nav-link-text">Members</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to={"/admin/product"}
                  onClick={closeSidenav}
                  tag={NavLinkRRD}
                >
                  <i className="fa fa-atom" />
                  <span className="nav-link-text">Product</span>
                </NavLink>
              </NavItem>
              {/* TODO: Remove this when billing is re-implemented */}
              {/*<NavItem>*/}
              {/*  <NavLink*/}
              {/*    to={"/admin/billing"}*/}
              {/*    onClick={closeSidenav}*/}
              {/*    tag={NavLinkRRD}*/}
              {/*  >*/}
              {/*    <i className="fa fa-money-bill" />*/}
              {/*    <span className="nav-link-text">Billing</span>*/}
              {/*  </NavLink>*/}
              {/*</NavItem>*/}
            </Nav>
          </Collapse>
        </div>
      </div>
    )
  ;
  return (
    <Navbar
      className={
        "sidenav navbar-vertical navbar-expand-xs navbar-dark bg-dark " +
        (rtlActive ? "" : "fixed-left")
      }
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}

export default Sidebar;
